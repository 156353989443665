body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hoverable-card:hover {
  background-color: #031B26;
  color: white;
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.hoverable-card:hover .outlined-card {
  background-color: white !important;
  color: black !important;
}

.hoverable-card:hover,
.hoverable-card:hover .lab-heading {
  background-color: #031B26;
  color: white !important;  /* The use of !important ensures this rule takes precedence */
}

.light-grey {
  background-color: #f7f7f7;
}